.App {
  font-family: sans-serif;
  /* text-align: center; */
  background-color: white;
  height: 100%;
  width: 100%;
}

/* Tailwindeth has been added but ye can remove it and roll yee own CSS */

/* I've had too much coffee */

/* yarr */

.button-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 16px;
  overflow-x: scroll;
  margin-left: 15px;
  margin-right: 25px;
}

.button_css {
  border: 1px solid #0051a6;
  font-size: 16px;
  padding: 8px 16px;
  border-radius: 20px;
  cursor: pointer;
  background: transparent;
  background: #fff;
  display: flex;
  align-items: center;
  gap: 6px;
}

.btn_border_rounded {
  border: 1px solid #0051a6;
  padding: 4px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn_rounded_center {
  width: 10px;
  height: 10px;
  background: #0051a6;
  border-radius: 50%;
}

.btn_rounded_center_white {
  width: 10px;
  height: 10px;
  background: #fff;
  border-radius: 50%;
}

button:hover .btn_rounded_center_white {
  background: #0051a6;
}

.route-response {
  margin-bottom: 16px;
  background-color: #f8f9fa;
  padding: 8px;
  border-radius: 4px;
}

.input-container {
  position: relative;
}
.abs_btn {
  position: absolute;
  right: 0;
  top: 4px;
  z-index: 200;
}

input {
  border: none;
  /* flex-grow: 1; */
  font-size: 16px;
  margin-left: 10px;
  outline: none;
}

/**
     * ==============================================
     * Dot Typing
     * ==============================================
     */
.dot-typing {
  position: relative;
  left: -9995px;
  width: 7px;
  height: 7px;
  border-radius: 5px;
  background-color: #3f3f3f;
  color: #3f3f3f;
  box-shadow: 9983px 0 0 0 #3f3f3f, 9995px 0 0 0 #3f3f3f, 10007px 0 0 0 #3f3f3f;
  animation: dot-typing 0.66s infinite;
  transition-timing-function: ease-out;
  transition-timing-function: cubic-bezier(0, 0, 0.58, 1);
}

@keyframes dot-typing {
  0% {
    box-shadow: 9983px 0 0 0 #3f3f3f, 9995px 0 0 0 #3f3f3f,
      10007px 0 0 0 #3f3f3f;
  }

  16.667% {
    box-shadow: 9983px -10px 0 0 #3f3f3f, 9995px 0 0 0 #3f3f3f,
      10007px 0 0 0 #3f3f3f;
  }

  33.333% {
    box-shadow: 9983px 0 0 0 #3f3f3f, 9995px 0 0 0 #3f3f3f,
      10007px 0 0 0 #3f3f3f;
  }

  50% {
    box-shadow: 9983px 0 0 0 #3f3f3f, 9995px -10px 0 0 #3f3f3f,
      10007px 0 0 0 #3f3f3f;
  }

  66.667% {
    box-shadow: 9983px 0 0 0 #3f3f3f, 9995px 0 0 0 #3f3f3f,
      10007px 0 0 0 #3f3f3f;
  }

  83.333% {
    box-shadow: 9983px 0 0 0 #3f3f3f, 9995px 0 0 0 #3f3f3f,
      10007px -10px 0 0 #3f3f3f;
  }

  100% {
    box-shadow: 9983px 0 0 0 #3f3f3f, 9995px 0 0 0 #3f3f3f,
      10007px 0 0 0 #3f3f3f;
  }
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* hide scrollbar but still allow scrolling */
::-webkit-scrollbar-button {
  display: none;
}

.message_block {
  background: #14417221;
  padding: 10px 15px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
}

.answer_right {
  background: #0a1f35a9;
  padding: 10px 15px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  border-top-left-radius: 20px;
  color: white;
}

.edit_icon {
  opacity: 10%;
}

.edit_icon:hover {
  opacity: 40%;
}

p {
  margin: 0 !important;
}

.flex_div_checkbox {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 15px;
}

.single_checkbox {
  border: 1px solid #0051a6;
  padding: 10px 15px;
  border-radius: 20px;
  cursor: pointer;
  background: transparent;
  display: flex;
  align-items: center;
}

.border_class {
  border: 1px solid #0a1f35a9;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  outline: none;
}

body {
  background-color: white;
}
.react-calendar {
  width: 350px;
  max-width: 100%;
  background: white;
  border: none;
  border-radius: 10px;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
}
.react-calendar--doubleView {
  width: 700px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.react-calendar button {
  border: 0;
  outline: none;
}

.react-calendar button:enabled:hover {
  cursor: pointer;
}
.react-calendar__navigation {
  display: flex;
  min-height: 100%;
  height: 44px;
  margin-bottom: 1em;
}

.react-calendar__navigation button {
  min-width: 44px;
  background: none;
  font-size: 20px;
  border-radius: 5px;
  margin: 10px 5px;
}
@media only screen and (max-width: 1025) {
  .react-calendar__navigation button {
    min-width: 44px;
    background: none;
    font-size: 16px;
    border-radius: 5px;
    margin: 5px;
  }
}
.react-calendar__navigation__label {
  height: 100%;
}

button.react-calendar__navigation__arrow {
  height: 100%;
}

.react-calendar__navigation button:disabled {
  background-color: #f0f0f0;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6;
}
.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
}
.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  font-weight: bold;
}
.react-calendar__month-view__days__day--weekend {
  color: #d10000;
}
.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}
.react-calendar__tile {
  border-radius: 1000px;
  max-width: 40px;
  max-height: 40px;
  height: 40px;
  margin: 5px;
  /* max-width: 100%; */
  background: none;
  text-align: center;
  line-height: 16px;
  padding: 10px 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.react-calendar__tile:enabled {
  /* background: #e7f1fa; */
  color: #403f3f;
}
.react-calendar__tile:disabled {
  background: #e1e1e1;
  color: #959292;
}
.react-calendar__tile:enabled:hover {
  background-color: #dfeaf4;
  color: #1673ca;
}
.react-calendar__tile:enabled:focus {
  background-color: #dfeaf4;
  color: white;
}
.react-calendar__tile--now {
  background: #bae6fd;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #dbeafe;
}
.react-calendar__tile--hasActive {
  background: #76baff;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
}
.react-calendar__tile--active:enabled {
  /* Important so that custom styled tiles (with class highlight) change to this color when selected */
  background: #006edc !important;
  color: white;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #1087ff;
  color: white;
}
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}

.react-calendar__tile--highlight:enabled {
  background-color: #80baf5;
  color: white;
  border: 1.5px solid #0088ffb0;
}

.react-calendar__tile--highlight:enabled:hover {
  background-color: #609bd6;
  border: 1px solid #008aff;
}
.actions {
  display: flex;
  align-items: center;
  gap: 10px;
}

.time-list {
  width: 100%;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  align-items: center;
}
.time-table {
  margin-top: 20px;
}
.time_acitve {
  width: 80px;
  font-size: 13px;
  color: black;
  padding: 9px 12px;
  font-weight: 300;
  color: #0051a6;
  cursor: pointer;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border-radius: 8px;
  background: #0050a624;
}
.time {
  width: 80px;
  font-size: 13px;
  color: black;
  padding: 9px 12px;
  font-weight: 300;
  color: #0051a6;
  cursor: pointer;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border-radius: 8px;
}
.time:hover {
  background: #0050a624;
}
.unactive_submit {
  background: gray;
  padding: 10px 70px;
  border-radius: 34px;
  cursor: not-allowed;
  color: #444;
}
.active_submit {
  background: #0051a6;
  padding: 10px 70px;
  border-radius: 34px;
  cursor: pointer;
  color: white;
}

.scrollable-container {
  max-height: calc(100vh - 10vh); /* Adjust the value to fit your layout */
  overflow-y: auto;
  padding-bottom: 30px;
}
