/* Infinite Carousel Styles */
.infinite-carousel {
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
  position: relative;
  margin-top: 20px;
}

.infinite-carousel-wrapper {
  display: flex;
  animation: scroll 30s linear infinite;
  width: max-content; /* This ensures the wrapper only expands to the content's width */
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-70%); /* Move it to the left by 100% of its width */
  }
}

.video-card {
  display: inline-block;
  margin: 0 10px;
  text-align: center;
  position: relative;
  cursor: pointer;
  width: 350px; /* Ensure the video card width matches the image size */
}

.video-thumbnail {
  width: 100%; /* Make the image fill the video card */
  height: 250px; /* Set the desired height */
  object-fit: cover;
}

.play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
}







/* Popup Styles */
.video-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8); /* Dark transparent background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  padding: 20px; /* Prevents edges from touching screen */
  box-sizing: border-box; /* Ensures padding is considered in total width/height */
}

.video-popup-content {
  position: relative;
  background: #fff;
  padding: 20px;
  max-width: 800px;
  width: 100%;
  max-height: 90vh; /* Limits the height of the popup */
  overflow: hidden; /* Prevents overflow */
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2); /* Subtle shadow */
  text-align: center;
}

.popup-video {
  width: 100%;
  height: auto;
  border-radius: 10px; /* Matches the popup's rounded corners */
}

.close-button {
  position: absolute;
  top: -10px; /* Adjusted to prevent overlap with video */
  right: -10px;
  font-size: 30px;
  color: #333;
  cursor: pointer;
  /* background: #fff; */
  border-radius: 50%; /* Circular background */
  width: 40px; /* Width of the close button */
  height: 40px; /* Height of the close button */
  display: flex;
  align-items: center;
  justify-content: center;
  /* box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);  */
}
