/* ContrastVideoPlayer.css */
.loading-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(
    0,
    0,
    0,
    0.929
  ); /* White semi-transparent background */
  z-index: 1; /* Place the loading overlay above the iframe */
}

.loading-circle {
  border: 4px solid rgba(255, 255, 255, 0.4); /* White semi-transparent border */
  border-top: 4px solid #fff; /* White border for the top side to create a circle effect */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite; /* Apply a rotation animation */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
