.accordion::before {
  display: none;
  opacity: 0;
}

video::before {
  display: none;
  opacity: 0;
}

video::-webkit-media-controls-panel {
  display: none !important;
  opacity: 1 !important;
}
