/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: cwb;
  src: url('./assets/font/cwb.otf');
}

@font-face {
  font-family: uber;
  src: url('./assets/font/uber.otf');
}

.font_uber {
  font-family: uber !important;
}

.home_border:hover {
  border: 1px solid #df4343;
}

img {
  border-style: none !important;
}

.show_element {
  display: none;
}

.button_hover:hover .hide_element {
  display: none;
}

.button_hover:hover .show_element {
  display: block;
}

.tool_tip {
  opacity: 0;
  top: 0px;
  transition: 0.3s ease all;
}

.tooltip_container:hover .tool_tip {
  opacity: 1;
}

.img_hover {
  opacity: 0;
  transform: scale(0.9);
  transition: 0.3s ease all;
  z-index: 20;
  background: #3898ec86;
  color: white;
}

.img_hover_container:hover .img_hover {
  opacity: 1;
  transform: scale(1);
  z-index: 20;
  background: #3898ecb9;
}

.MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
  padding: 2px !important;
}

.MuiInputLabel-formControl {
  top: -5px !important;
}

.slick-list {
  margin: 0 -5px;
}

.slick-slide > div {
  padding: 0 5px;
}

.hvr_text {
  bottom: -10px;
  opacity: 0;
  transition: 0.3s linear all;
}

.hvr_parent:hover .hvr_text {
  opacity: 1;
  bottom: 10px;
}

.hvr_bg {
  background-image: linear-gradient(0deg, #0d0e0eb2 11%, rgba(13, 14, 14, 0));

  transition: 0.3s linear all;
}

.hvr_parent:hover .hvr_bg {
  background-image: linear-gradient(0deg, #0d0e0ee0 11%, rgba(13, 14, 14, 0));
  /* background: rgba(0, 0, 0, 0.5); */
}

.min_h_pic {
  min-height: 240px;
}

.hvr_parent:hover .hvr_pic {
  transition: 0.3s linear all;
  transform: scale(1.2);
}

.animation_right {
  position: relative;
  transition: 10s linear;
  animation-name: right_scoll;
  animation-duration: 80s;
  animation-iteration-count: infinite;
}

.animation_left {
  position: relative;
  transition: 10s linear;
  animation-name: left_scoll;
  animation-duration: 60s;
  animation-iteration-count: infinite;
}

@keyframes right_scoll {
  0% {
    right: 0%;
    top: 0;
  }

  25% {
    right: 50%;
    top: 0;
  }

  50% {
    right: 100%;
    top: 0;
  }

  75% {
    right: 50%;
    top: 0;
  }

  100% {
    right: 0%;
    top: 0;
  }
}

@keyframes left_scoll {
  0% {
    right: 50%;
    top: 0;
  }

  25% {
    right: 0%;
    top: 0;
  }

  50% {
    right: 100%;
    top: 0;
  }

  75% {
    right: 50%;
    top: 0;
  }

  100% {
    right: 0%;
    top: 0;
  }
}

.border_parent {
  cursor: pointer;
}

.border_bottom {
  height: 2px;
  width: 100%;
}

.border_hover {
  height: 2px;
  width: 0%;
  opacity: 0;
}

.border_parent:hover {
  color: black;
}

.border_parent:hover .border_hover {
  transition: 0.6s linear all;
  height: 2px;
  width: 100%;
  opacity: 1;
  color: black !important;
}

.button_animation {
  position: relative;

  animation: button_ani ease 2s;
}

.button_animation2 {
  position: relative;

  animation: button_ani ease 2s;
}

@keyframes button_ani {
  0% {
    left: 0;
    top: 15px;
    opacity: 0;
  }

  100% {
    left: 0;
    top: 0;
    opacity: 1;
  }
}

.sm_bg {
  background: #3898ec27;
}

.section_bg {
  background: #3898ec17;
}

#showheader {
  display: none;
}

#header_box:hover #showheader {
  display: block;
}

.cwb_font {
  font-family: cwb !important;
}

.bg_transparent:hover {
  background: rgba(255, 255, 255, 0.2);
}
.page-link {
  border-radius: 50% !important;
  padding: 6px 11px !important;
  font-size: 12px !important;
}
.pagination {
  gap: 5px !important;
}

.paginate_react ul {
  display: flex;
  align-items: center;
  gap: 10px;
}
.paginate_react ul li {
  padding: 7px 15px;
}
.paginate_react .selected {
  background: #3898ec;
  color: white;
  border-radius: 50%;
}
.apexChartNew .apx-legend-position-bottom {
  inset: auto -6px 124px !important;
}
.border-b-needs {
  border-bottom: 1px solid #2697fac2 !important;
}
.MuiTableSortLabel-root {
  font-weight: bold !important;
}

/* Typing Animation Css */
.typingIndicatorBubble {
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 52px; */
  /* height: 40px; */
  margin: 0px 8px;
  /* background-color: #f0f1f1; */
  border-radius: 12px;
}

.typingIndicatorBubbleDot {
  width: 4px;
  height: 4px;
  margin-right: 4px;
  background-color: #57585a;
  border-radius: 50%;
  animation-name: bounce;
  animation-duration: 1.3s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.typingIndicatorBubbleDot:first-of-type {
  margin: 0px 4px;
}

.typingIndicatorBubbleDot:nth-of-type(2) {
  animation-delay: 0.15s;
}

.typingIndicatorBubbleDot:nth-of-type(3) {
  animation-delay: 0.3s;
}

@keyframes bounce {
  0%,
  60%,
  100% {
    transform: translateY(0);
  }
  30% {
    transform: translateY(-4px);
  }
}

/* https://css-tricks.com/books/greatest-css-tricks/scroll-shadows/ */
.scroll-shadows {
  overflow: auto;

  background:
    /* Shadow Cover TOP */ linear-gradient(
        rgb(249 250 251) 30%,
        rgba(255, 255, 255, 0)
      )
      center top,
    /* Shadow Cover BOTTOM */
      linear-gradient(rgba(255, 255, 255, 0), rgb(249 250 251) 70%) center
      bottom,
    /* Shadow TOP */
      radial-gradient(
        farthest-side at 50% 0,
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0)
      )
      center top,
    /* Shadow BOTTOM */
      radial-gradient(
        farthest-side at 50% 100%,
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0)
      )
      center bottom;

  background-repeat: no-repeat;
  background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
  background-attachment: local, local, scroll, scroll;
}
