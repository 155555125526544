.initial-message-bubble {
  position: relative;
  /* background: #fff; */
  border: 1px solid rgba(0, 0, 0, 0.086);
  padding: 10px;
  width: 100%;
  border-radius: 10px;
  transition: all 0.3s linear;
  z-index: 2147483647;
  background-color: rgba(255, 255, 255, 0.86);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  /* box-shadow: rgba(211, 211, 211, 0.326) 0px 4px 20px; */
}

.chatbot-button-close {
  position: absolute;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  right: -12px;
  top: -12px;
  height: 24px;
  width: 24px;
  background: rgba(86, 80, 80, 0.7);
  border-radius: 15px;
}

.cta-bubble-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  border-radius: 2px;
  border: 1px solid #ededed;
  margin-top: 4px;
  gap: 4px;
}

.cta-bubble-headline {
  color: #000;
  font-size: 16px;
  font-family: Inter, sans-serif;
  font-weight: 700;
  text-align: center;
}

.cta-bubble-subtitle {
  color: #000;
  font-size: 11px;
  font-family: Inter, sans-serif;
  text-align: center;
}

.bubble-profile-pic {
  margin-left: -23px;
  position: relative;
  background-color: #342e2e;
  border-radius: 100%;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  align-content: center;
  color: white;
  justify-content: center;
}

.bubble-team-name {
  font-family: Manrope, sans-serif;
  margin-left: 10px;
}

.bubble-message-timestamp {
  color: lightgray;
  font-size: 12px;
  font-family: Manrope, sans-serif;
  margin-left: 5px;
}
