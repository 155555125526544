.SessionUrl {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 16px 8px 16px;
  width: 100%;
  z-index: 2;
  position: relative;
  gap: 0.5rem;
}

.Dashboard__window {
  background: linear-gradient(0deg, #202747, #202747), #0e1019;
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}

.Dashboard__split {
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: #ffffff;
}

.Dashboard__main {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.Template__content-main {
  position: relative;
  overflow: auto;
  flex: 1;
  min-width: 0;
  z-index: 1;
}

.Template__content-main--padding-none {
  padding: 0;
}

.Template__content {
  position: relative;
  display: flex;
  justify-content: center;
  flex: 1;
  overflow: hidden;
  z-index: 1;
}

.Template {
  display: flex;
  height: 100%;
  width: 100%;
  background-color: #fbfbfb;
  overflow: hidden;
}

.SessionUrl__url {
  flex: 1;
  gap: 8px;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  background: #ffffff;
  mix-blend-mode: normal;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  min-width: 220px;
  height: 40px;
  padding: 0 6px 0 12px;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
}

.SessionUrl__url-text {
  flex: 1;
  color: #515151;
}

.PeerStatusIndicator {
  width: 30px;
  height: 30px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px;
  margin-right: 8px;
}

.VisitorOverview__content {
  height: 100%;
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.VisitorOverview {
  flex: 1;
  background: linear-gradient(
      0deg,
      rgba(243, 243, 248, 0.7),
      rgba(243, 243, 248, 0.7)
    ),
    #ffffff;
  display: flex;
  flex-direction: column;
  height: calc(100% - 74px);
}

.Dashboard__window {
  background: linear-gradient(0deg, #202747, #202747), #0e1019;
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}

.Dashboard__split {
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: #ffffff;
}

.Dashboard__main {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.Template__content-main {
  position: relative;
  overflow: auto;
  flex: 1;
  min-width: 0;
  z-index: 1;
}

.Template__content-main--padding-none {
  padding: 0;
}

.Template__content {
  position: relative;
  display: flex;
  justify-content: center;
  flex: 1;
  overflow: hidden;
  z-index: 1;
}

.Template {
  display: flex;
  height: 100%;
  width: 100%;
  background-color: #fbfbfb;
  overflow: hidden;
}

#app {
  width: 100%;
  height: 100%;
}

body {
  width: 100%;
  height: 100%;
  margin: 0;
  color: #274060;
  font-size: 14px;
  font-family: 'Jakarta Sans', Arial, sans-serif;
  font-variant: normal;
  line-height: 1.5715;
  background-color: #fff;
  font-feature-settings: normal;
  -webkit-font-feature-settings: normal;
}

html {
  width: 100%;
  height: 100%;
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-size: 16px;
  scroll-behavior: smooth;
}

.VisitorOverview__topPages {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 32px;
  gap: 16px;
  background: #ffffff;
  border-radius: 20px;
  border: 1px solid #e8e9ed;
}

*,
:before,
:after {
  box-sizing: border-box;
}

:selection {
  color: #fff;
  background: #1e2749;
}

.VisitorOverview__topPages-header {
  font-style: normal;
  font-weight: 500;
  font-size: 19px;
  line-height: 24px;
  display: flex;
  align-items: center;
  gap: 12px;
  color: #191d29;
}

table {
  border-collapse: collapse;
}

.VisitorOverview__topPages-section {
  width: 100%;
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  color: #1e2749;
}

svg:not(:root) {
  overflow: hidden;
}

.VisitorOverview__topPages-header svg {
  width: 25px;
  min-width: 25px;
  height: 25px;
  fill: currentColor;
}

.VisitorOverview__topPages-page {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 6px 0;
}

.VisitorOverview__topPages-visits {
  text-align: right;
}

.VisitorOverview__topPages-menu {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 16px;
  background: #f4f5f6;
  border-radius: 7px;
}

.VisitorOverview__topPages-page svg {
  width: 12px;
  min-width: 12px;
  height: 12px;
  fill: #8f93a2;
}

.VisitorOverview__topPages-menu svg {
  width: 14px;
  height: 14px;
  color: #8e95a4;
}
